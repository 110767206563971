<div class="parent">
  <div class="text">
    <h1>
      Hi,
      <br />
      I'm Tom
      <br />
      a Frontend Developer
    </h1>
  </div>
  <div class="image">
    <img src="../../assets/tom-gaming.png" width="400" height="400" />
  </div>
  <div class="contact">
    <a class="btn btn-primary" href="mailto:tomcockram6@gmail.com">Contact me</a>
  </div>
  <div class="info">
    <p>
      I'm a self taught frontend developer who uses Angular to build responsive websites. With experience as a QA member and a frontend dev I'm able
      to provide quality web applications
    </p>
  </div>
</div>
