<div>
  <ul>
    <li>
      <a href="https://twitter.com/HaldunIsTaken">Twitter</a>
    </li>
    <li>
      <a href="https://github.com/TomCockram">Github</a>
    </li>
    <li>
      <a href="https://tomcockram.contently.com/">Writing Portfolio</a>
    </li>
    <li>
      <a href="https://www.linkedin.com/in/tom-cockram-231327180/">Linkedin</a>
    </li>
  </ul>
</div>
