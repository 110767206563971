<h1>Skills</h1>

<div class="parent">
  <h4>Frontend</h4>
  <ul>
    <li>Angular</li>
    <li>CSS</li>
    <li>Javascript</li>
    <li>Typescript</li>
  </ul>

  <h4>Quality Assurance</h4>
  <ul>
    <li>Android Testing</li>
    <li>Github actions</li>
    <li>Automated Testing</li>
  </ul>

  <h4>Misc</h4>
  <ul>
    <li>Github</li>
    <li>Git</li>
    <li>Git Kraken</li>
  </ul>
</div>
