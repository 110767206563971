<div class="full-height center-x-y">
  <div class="center"><p>Coming soon...</p></div>
  <div class="center"><p>But we can still connect</p></div>

  <div class="center">
    <ul class="socials">
      <li>
        <a href="https://twitter.com/HaldunIsTaken">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            width="50"
            height="50"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 256 256"
          >
            <path
              d="M247.4 68.9A8 8 0 0 0 240 64h-30.4a47.8 47.8 0 0 0-75.2-10.1A47.7 47.7 0 0 0 120 88v6.1C79.3 83.5 46 50.7 45.7 50.3a8 8 0 0 0-8.1-1.9a8.1 8.1 0 0 0-5.5 6.2c-8.7 48.2 5.8 80.5 19.5 99.1a108.6 108.6 0 0 0 24.7 24.4c-15.3 17.3-38.9 26.3-39.1 26.4a8 8 0 0 0-3.9 11.9c.8 1.2 3.8 5.1 11.1 8.8c9.1 4.5 21.1 6.8 35.6 6.8c70.5 0 129.5-54.3 135.5-124.2l30.2-30.1a8.4 8.4 0 0 0 1.7-8.8zm-45.3 29.7a7.8 7.8 0 0 0-2.3 5.2C195.7 166.7 143.1 216 80 216c-10.6 0-18-1.4-23.2-3.1c11.5-6.2 27.5-17 37.9-32.5a8 8 0 0 0 1-6.4a8.1 8.1 0 0 0-4.1-5.1c-.1-.1-14.9-7.8-27.6-25.3c-14.4-19.8-20.5-43.9-18.1-71.7c15.8 13 46 34.2 80.8 40a8.1 8.1 0 0 0 6.5-1.8a8.2 8.2 0 0 0 2.8-6.1V88a32 32 0 0 1 61.3-12.8a8.1 8.1 0 0 0 7.4 4.8h16z"
              fill="currentColor"
            />
          </svg>
        </a>
      </li>
      <li>
        <a href="https://github.com/TomCockram"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            width="50"
            height="50"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 48 48"
          >
            <g fill="#000" fill-rule="evenodd" clip-rule="evenodd">
              <path
                d="M24 4C12.954 4 4 12.954 4 24s8.954 20 20 20s20-8.954 20-20S35.046 4 24 4zM0 24C0 10.745 10.745 0 24 0s24 10.745 24 24s-10.745 24-24 24S0 37.255 0 24z"
              />
              <path
                d="M19.183 45.471c-.193-.25-.193-2.474 0-6.673c-2.072.072-3.38-.072-3.927-.431c-.82-.54-1.64-2.2-2.367-3.371c-.728-1.171-2.343-1.356-2.995-1.618c-.652-.262-.816-1.328 1.797-.522c2.613.807 2.74 3.005 3.565 3.518c.824.514 2.795.29 3.689-.122c.893-.412.827-1.944.986-2.551c.202-.567-.508-.693-.524-.697c-.872 0-5.453-.997-6.712-5.433c-1.258-4.437.363-7.337 1.228-8.584c.576-.83.525-2.602-.153-5.313c2.463-.315 4.364.46 5.703 2.326c.002.01 1.755-1.043 4.527-1.043c2.772 0 3.755.858 4.514 1.043c.759.185 1.366-3.266 6.053-2.326c-.979 1.923-1.798 4.326-1.173 5.313c.626.988 3.08 4.128 1.572 8.584c-1.005 2.97-2.98 4.781-5.928 5.433c-.338.107-.507.281-.507.521c0 .36.457.4 1.115 2.087c.439 1.124.47 4.336.095 9.635c-.95.242-1.69.404-2.22.488c-.937.147-1.954.23-2.954.261c-1 .032-1.348.029-2.73-.1a19.798 19.798 0 0 1-2.654-.425z"
              />
            </g></svg
        ></a>
      </li>
      <li>
        <a href="https://tomcockram.contently.com/"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            width="50"
            height="50"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M487.97 155.853a24.035 24.035 0 0 0-7-18.166L376.126 32.842a24 24 0 0 0-35.546 1.772l-89.936 109.922l-130.137 26.514a27.1 27.1 0 0 0-20.025 17.026L17.883 405.84a27.268 27.268 0 0 0 6.205 28.917l53.271 53.271a27.263 27.263 0 0 0 28.915 6.207l217.86-82.635a27.144 27.144 0 0 0 16.95-19.655l28.1-128.7L479.2 173.232a24.041 24.041 0 0 0 8.77-17.379zm-177.6 226.741L97.807 463.222l-13.142-13.143l99.36-99.36a56.061 56.061 0 1 0-22.268-22.986l-99.72 99.72l-13.143-13.143l80.576-212.429l124.717-25.41l83.052 83.051zM187.42 301.9a24 24 0 1 1 24 24a24 24 0 0 1-24-24zm168.391-69.065L280.973 158l78.776-96.28l92.343 92.343z"
            /></svg
        ></a>
      </li>
      <li>
        <a href="https://www.linkedin.com/in/tom-cockram-231327180/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            width="50"
            height="50"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <circle cx="4.983" cy="5.009" r="2.188" fill="currentColor" />
            <path
              d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118c1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783c-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zm-6.142 0H6.87v12.139H3.095z"
              fill="currentColor"
            />
          </svg>
        </a>
      </li>
    </ul>
  </div>
</div>
