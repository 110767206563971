<div>
  <ul>
    <li>
      <a id="home" [routerLink]="'home'" routerLinkActive="active">Home</a>
    </li>
    <!-- <li>
      <a id="about" [routerLink]="'about'" routerLinkActive="active">About</a>
    </li> -->
    <li>
      <a id="skills" [routerLink]="'skills'" routerLinkActive="active">Skills</a>
    </li>
    <li>
      <a id="writing" [routerLink]="'writing'" routerLinkActive="active">Writing</a>
    </li>
    <li>
      <a id="contact" [routerLink]="'contact'" routerLinkActive="active">Contact</a>
    </li>
  </ul>
</div>
